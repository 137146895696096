<template>
  <div>
    <div
      class="hidden lg:flex"
      role="region"
      aria-roledescription="carousel"
      aria-label="Annunci in evidenza"
      aria-live="polite"
    >
      <div class="ucrs-carousel__left">
        <div
          v-for="(property, i) in items"
          :key="i"
          role="group"
          aria-roledescription="slide"
          :aria-label="`${i + 1} di ${totalSlides + 1}`"
          class="ucrs-carousel__info"
          :class="{
            'ucrs-carousel__info--visible': currentSlide === i,
          }"
        >
          <p v-if="property.city" class="mb-4 text-sm uppercase font-bold">
            {{ property.city }}
          </p>
          <h3 class="mb-4 font-bold text-2xl">
            {{ property.title }}
          </h3>
          <div v-if="showcases" class="my-8 flex justify-between">
            <div class="flex">
              <SvgIcon name="locator" class="w-7 h-7 mr-4" />
              <span
                v-dompurify-html="
                  property.location.locality.thoroughfare.address
                "
                class="font-bold"
              ></span>
            </div>
            <p class="text-2xl font-bold">{{ propertyPrice(property) }}</p>
          </div>
          <ul
            v-if="property.features"
            class="flex justify-start items-center text-center"
          >
            <li class="ucrs-carousel__feature">
              {{ property.features.surface }}
              {{ property.features.surface_unit }}
              <sup>2</sup>
            </li>
            <li v-if="property.features.rooms" class="ucrs-carousel__feature">
              {{ property.features.rooms }} locali
            </li>
            <li class="ucrs-carousel__feature">
              {{ property.features.energy_class }} cl.energetica
            </li>
            <li class="ucrs-carousel__feature"></li>
          </ul>
          <slot name="description"></slot>
          <p class="mb-16 mt-8">
            {{ showcases ? property.excerpt : property.caption }}
          </p>
          <OButton
            variant="primary"
            tag="a"
            :href="`${property.url}`"
            :aria-label="`Scopri di più, ${property.title}`"
            target="_blank"
          >
            Scopri di più
          </OButton>
        </div>
      </div>
      <div class="ucrs-carousel__right relative">
        <div class="ucrs-carousel__right--img" aria-live="polite">
          <div
            v-for="(property, index) in items"
            :key="index"
            role="group"
            aria-roledescription="slide"
            :aria-label="`${index + 1} di ${totalSlides + 1}`"
            class="ucrs-carousel__slide transition-all duration-500 ease-out"
            :class="{
              'ucrs-carousel__slide--active': currentSlide === index,
              'pointer-events-none': currentSlide !== index,
            }"
            :style="computeTransform(index)"
          >
            <NuxtLink
              v-if="showcases"
              :to="property.url"
              target="_blank"
              class="group outline-none my-auto"
              :tabindex="currentSlide === index ? '0' : '-1'"
            >
              <NuxtImg
                class="object-cover rounded group-focus-visible:outline-none group-focus-visible:ring-2 group-focus-visible:ring-offset-2 group-focus-visible:ring-blue"
                :src="$utilities.imgAlias(property.image.src)"
                loading="lazy"
                :alt="property.title"
                width="360"
                height="440"
              />
            </NuxtLink>
            <NuxtImg
              v-else
              :key="index"
              class="w-full h-full object-cover rounded"
              :src="`/assets/img/visual/${property.mobile}`"
              :alt="property.alt"
            />
          </div>
        </div>
        <div class="ucrs-carousel__arrowContainer">
          <UcrsButton
            class="bg-blue rounded-full ucrs-carousel__icon-back"
            aria-label="Annuncio precedente"
            @click="prev"
          >
            <SvgIcon name="angle-left-white" class="h-4 w-4" />
          </UcrsButton>
          <div class="font-medium">
            {{ currentSlide + 1 }}/{{ totalSlides + 1 }}
          </div>
          <UcrsButton
            class="bg-blue rounded-full ucrs-carousel__icon-next"
            aria-label="Annuncio successivo"
            @click="next"
          >
            <SvgIcon name="angle-right-white" class="h-4 w-4" />
          </UcrsButton>
        </div>
      </div>
    </div>
    <ClientOnly>
      <Swiper
        :options="swiperOptions"
        pagination
        dots-position="outside"
        class="lg:hidden"
      >
        <div
          v-for="(property, i) in items"
          :key="i"
          class="swiper-slide text-left cursor-pointer"
        >
          <div class="rounded overflow-hidden relative">
            <NuxtImg
              v-if="showcases"
              class="w-full h-80 object-cover rounded"
              :src="$utilities.imgAlias(property.image.src)"
              alt="Immobili UniCredit"
              loading="lazy"
              height="200"
            />
            <NuxtImg
              v-else
              class="w-full h-full object-cover rounded"
              :src="`/assets/img/visual/${property.mobile}`"
              :alt="property.alt"
              loading="lazy"
            />
          </div>
          <div class="px-6 py-8">
            <h2 class="mb-4 font-bold text-2xl">
              {{ property.title }}
            </h2>
            <div v-if="showcases" class="flex justify-between flex-nowrap mb-4">
              <ul
                v-if="property.features"
                class="flex justify-between items-center overflow-hidden"
              >
                <li class="text-xl font-bold whitespace-nowrap pr-6">
                  {{ propertyPrice(property) }}
                </li>
                <li class="ucrs-carousel__feature">
                  {{ property.features.surface }}
                  {{ property.features.surface_unit }}
                  <sup>2</sup>
                </li>
                <li
                  v-if="property.features.rooms"
                  class="ucrs-carousel__feature"
                >
                  {{ property.features.rooms }} locali
                </li>
                <li
                  class="text-ellipsis overflow-hidden ucrs-carousel__feature"
                >
                  {{ property.features.energy_class }} cl.energetica
                </li>
              </ul>
            </div>
            <p class="mb-16">
              {{ showcases ? property.excerpt : property.caption }}
            </p>
            <OButton
              variant="primary"
              class="w-full text-center md:w-auto"
              tag="a"
              :href="`${property.url}`"
            >
              Scopri di più
            </OButton>
          </div>
        </div>
      </Swiper>
    </ClientOnly>
  </div>
</template>

<script>
import { propertyPrice } from '~/assets/js/methods'
import { cardFeatures } from '~/assets/js/computed'

import UcrsButton from '~/components/UcrsButton.vue'
import Swiper from '~/components/Swiper.vue'

export default {
  components: {
    UcrsButton,
    Swiper,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },

    showcases: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentSlide: 0,
      elementWidth: 242,
      spacing: 40,
      swiperOptions: {
        slidesPerView: 1.2,
        spaceBetween: 20,
        breakpoints: {
          480: {
            slidesPerView: 2.2,
          },
        },
      },
    }
  },

  computed: {
    cardFeatures,
    totalSlides() {
      return this.items.length - 1
    },
  },
  methods: {
    propertyPrice,
    next() {
      if (this.currentSlide === this.totalSlides) {
        this.currentSlide = 0
      } else {
        this.currentSlide = this.currentSlide + 1
      }
    },
    prev() {
      if (this.currentSlide === 0) {
        this.currentSlide = this.totalSlides
      } else {
        this.currentSlide = this.currentSlide - 1
      }
    },

    computeTransform(index) {
      switch (true) {
        case index === this.currentSlide:
          return `transform: translate3d(78px, -50%, 0)`
        case this.currentSlide < index &&
          (index < this.totalSlides || index === this.totalSlides) &&
          index === this.currentSlide + 1:
          return `transform: translate3d(0, -50%, 0)`
        case this.currentSlide < index &&
          (index < this.totalSlides || index === this.totalSlides) &&
          index === this.currentSlide + 2:
          return `transform: translate3d(${
            this.elementWidth + this.spacing
          }px, -50%, 0)`
        case this.currentSlide < index &&
          (index < this.totalSlides || index === this.totalSlides) &&
          index === this.currentSlide + 3:
          return `transform: translate3d(${
            (this.elementWidth + this.spacing) * this.totalSlides -
            (this.elementWidth + this.spacing) * 2
          }px, -50%, 0)`
        case (this.currentSlide > index ||
          this.currentSlide === this.totalSlides) &&
          index < this.totalSlides &&
          index === this.currentSlide - 1:
          return `transform: translate3d(${
            (this.elementWidth + this.spacing) * this.totalSlides -
            (this.elementWidth + this.spacing)
          }px, -50%, 0)`
        case (this.currentSlide > index ||
          this.currentSlide === this.totalSlides) &&
          index < this.totalSlides &&
          index === this.currentSlide - 2:
          return `transform: translate3d(${
            (this.elementWidth + this.spacing) * this.totalSlides -
            (this.elementWidth + this.spacing) * 2
          }px, -50%, 0)`
        case (this.currentSlide > index ||
          this.currentSlide === this.totalSlides) &&
          index < this.totalSlides &&
          index === this.currentSlide - 3:
          return `transform: translate3d(${
            (this.elementWidth + this.spacing) * this.totalSlides -
            (this.elementWidth + this.spacing) * 3
          }px, -50%, 0)`
        case this.currentSlide === this.totalSlides &&
          index < this.totalSlides &&
          index === 0:
          return `transform: translate3d(0px, -50%, 0)`
        default:
          return `transform: translate3d(${
            (this.elementWidth + this.spacing) * this.totalSlides -
            (this.elementWidth + this.spacing)
          }px, -50%, 0)`
      }
    },
  },
}
</script>

<style lang="postcss">
.ucrs-carousel {
  &__feature {
    @apply font-bold whitespace-nowrap relative px-3;

    &:first-child {
      @apply pl-0;
    }

    &:not(:first-child)::before {
      @apply absolute w-0.5 top-1 bottom-1 left-0 bg-gray-lighter;

      content: '';
    }
  }
  &__icon-back {
    position: absolute;
    left: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #007a91;
    color: #fff;
    cursor: pointer;
  }

  &__icon-next {
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #007a91;
    color: #fff;
    border: 0;
    outline: 0;
    cursor: pointer;
  }

  &__left {
    position: relative;
    flex: 0 0 33%;
    margin-right: 40px;
    height: 448px;
  }
  &__info {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all;
    height: 448px;
    &--visible {
      visibility: visible;
      opacity: 1;
    }
  }
  &__right {
    flex: 1 1 100%;
    margin: 0;
    height: 448px;
    overflow: hidden;
    &--img {
      height: 448px;
      width: 5000px;
    }
  }

  &__arrowContainer {
    position: absolute;
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    margin-bottom: 4px; /* Needed for ring to show correctly on arrows */
    margin-left: auto;
    bottom: 0;
    right: 20px;
  }

  &__slide {
    position: absolute;
    width: 242px;
    height: 298px;
    left: 0;
    top: 50%;
    opacity: 0.5;
    &--active {
      width: 360px;
      height: 440px;
      z-index: 1;
      opacity: 1;
    }
  }
}
</style>
